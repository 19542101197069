import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RtErpcOrigin, RtErpcOriginAdapter } from '@bds/railtrac-models';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, delay, shareReplay } from 'rxjs/operators';
import ODataStore from 'devextreme/data/odata/store';
import { nameof } from '@bds/core';
import { ApiErrorHandlerService } from '@bds/helpers';

export abstract class BdsErpcOriginApiServiceOptions {
    apiUrl = `api/ErpcOrigin/`;
    odataUrl = `odata/ErpcOrigin`;
}

@Injectable({
    providedIn: 'root',
})
export class BdsErpcOriginService {
    constructor(
        private http: HttpClient,
        private options: BdsErpcOriginApiServiceOptions,
        public adapter: RtErpcOriginAdapter,
        private apiErrorHandler: ApiErrorHandlerService,
    ) {}

    create(origin: RtErpcOrigin): Observable<RtErpcOrigin> {
        return this.http
            .post<RtErpcOrigin>(`${this.options.apiUrl}`, this.adapter.toServer(origin))
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }

    delete(key: number): Observable<RtErpcOrigin> {
        return this.http.delete<RtErpcOrigin>(`${this.options.apiUrl}/${key}`).pipe(
            map((data: any) => {
                return !!data ? this.adapter.adapt(data) : null;
            }),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    getODataStore(key: keyof RtErpcOrigin = 'ormId'): ODataStore {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: key,
            onLoaded: (data) => {
                data.forEach((item: RtErpcOrigin) => {
                    item.originCode = item.originCode?.trim();
                    item.erpcOrigin = item.erpcOrigin?.trim();
                });
            },
        });
    }

    getODataStoreWithKey(key: string): ODataStore {
        return new ODataStore({
            version: 4,
            url: this.options.odataUrl,
            key: key,
        });
    }

    getByCityState(city: string, state: string): Observable<RtErpcOrigin> {
        if (city && state) {
            const filter = `$filter=erpcOrigin eq '${city}' and erpcState eq '${state}'`;
            return this.http.get<any>(`${this.options.odataUrl}?$top=1&${filter}`).pipe(
                map((data) => {
                    if (data && data.value && data.value.length === 1) {
                        return this.adapter.adapt(data.value[0]);
                    }
                    return null;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
        } else {
            return of(null);
        }
    }

    getBySplc(splc: string): Observable<RtErpcOrigin> {
        if (splc) {
            const filter = `$filter=splc eq '${splc}'`;
            return this.http.get<any>(`${this.options.odataUrl}?$top=1&${filter}`).pipe(
                map((data) => {
                    if (data && data.value && data.value.length) {
                        return this.adapter.adapt(data.value[0]);
                    }
                    return null;
                }),
                shareReplay(),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
        } else {
            return of(null);
        }
    }

    getOriginCountByOriginCode(originCode: string): Observable<number> {
        if (originCode) {
            const filter = `$filter=toupper(trim(originCode)) eq '${originCode
                .trim()
                .toUpperCase()}'`;
            return this.http.get<number>(`${this.options.odataUrl}/$count?$top=1&${filter}`);
        } else {
            return of(0);
        }
    }

    read(key: number, expand?: string[]): Observable<RtErpcOrigin> {
        let expandUrl = '';
        if (!!expand && expand.length > 0) {
            expandUrl = '?$expand=';
            expand.forEach((child) => (expandUrl = expandUrl + child + ','));
            expandUrl = expandUrl.slice(0, expandUrl.length - 1);
        }

        return this.http.get<RtErpcOrigin>(`${this.options.odataUrl}(${key})` + expandUrl).pipe(
            map((data: any) => this.adapter.adapt(data)),
            shareReplay(),
            catchError((err) => this.apiErrorHandler.handleError(err)),
        );
    }

    update(key: number, origin: RtErpcOrigin): Observable<RtErpcOrigin> {
        return this.http
            .put<RtErpcOrigin>(`${this.options.apiUrl}/${key}`, this.adapter.toServer(origin))
            .pipe(
                map((data: any) => {
                    return !!data ? this.adapter.adapt(data) : null;
                }),
                catchError((err) => this.apiErrorHandler.handleError(err)),
            );
    }
}
