<form [formGroup]="formGroup">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Car Init <span class="dirty-indicator">*</span> </mat-label>
                        <input matInput bdsShoutcase formControlName="carInit" />
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Car No<span class="dirty-indicator">*</span> </mat-label>
                        <input matInput bdsShoutcase formControlName="carNo" bdsZeroPad [pad]="6" />
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <bds-date-time-picker
                        formControlName="hotListDate"
                        label="Hot List Date"
                    ></bds-date-time-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label
                            >Hot List Category<span class="dirty-indicator">*</span>
                        </mat-label>
                        <input matInput bdsShoutcase formControlName="hotListCategory" />
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Hot List Type<span class="dirty-indicator">*</span> </mat-label>
                        <input matInput bdsShoutcase formControlName="hotListType" />
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Comment Type</mat-label>
                        <mat-select formControlName="commentType">
                            <mat-option value="">-- General Comment --</mat-option>
                            <mat-option
                                *ngFor="let item of commentTypes | async"
                                [value]="item.category"
                                >{{ item.description }}</mat-option
                            >
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-sm-3">
                    <mat-form-field
                        class="highlight-dirty bds-mat-dense-form-field w-100"
                        floatLabel="always"
                        appearance="fill"
                    >
                        <mat-label>Comment Code<span class="dirty-indicator">*</span> </mat-label>
                        <mat-select formControlName="commentCode">
                            <mat-option value=""></mat-option>
                            <mat-option *ngFor="let item of defaultComments" [value]="item.code">
                                {{ item.code }} - {{ item.comment }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <!--            <mat-form-field class="response-area"-->
            <!--                            style="padding-top: 5px;"-->
            <!--                            floatLabel="always"-->
            <!--                            appearance="fill">-->
            <!--                <mat-label>Response<span class="dirty-indicator">*</span></mat-label>-->
            <!--                <textarea matInput bdsShoutcase [formControl]="element.controls.userResponse" rows="3"-->
            <!--                          cols="100"></textarea>-->
            <!--            </mat-form-field>-->
            <mat-form-field floatLabel="always" appearance="fill" class="textarea-container">
                <mat-label>Comments<span class="dirty-indicator">*</span></mat-label>
                <textarea
                    matInput
                    bdsShoutcase
                    formControlName="comments"
                    cols="100"
                    rows="4"
                    maxlength="255"
                ></textarea>
                <p class="small-gray-text">Maximum length of 255 characters only.</p>
            </mat-form-field>
        </div>
    </div>
</form>
